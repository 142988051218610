import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from 'axios';
import { counterContext } from './context/context';

const RootComponent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          // 'Authorization': 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI5MSIsInJvbGVJZCI6NCwicm9sZU5hbWUiOiJSZXRhaWxlciIsImlhdCI6MTcxNTMyNDMwNSwiZXhwIjoxNzQ2ODYwMzA1LCJpc3MiOiJwaG9uZWRla2hvc2VjcmV0aXNzdWVyIn0.KL7ATOjiOm1FqcLX4-JrJvlCJMWSnJ0O574fOdfN4bM'
        }
        // http://rizycosmetic-001-site3.atempurl.com/v1/blog/getAllBlogs
        const response = await axios.post("http://api.phonedekho.co.in/v1/customer/blog/getAllBlogs",{},{
          headers: headers
        });

        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      console.log(data)
    };

    fetchData();
  }, []);

  return (
    <React.StrictMode>
      <counterContext.Provider value={data}>
        <App />
      </counterContext.Provider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);
