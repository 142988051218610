import React, { useEffect } from 'react'
import Locker_img from '../assets/images/43.png'
import we_are from '../assets/images/44.png'
import retailer from '../assets/images/45.png'
import finance from '../assets/images/46.png'
import customer from '../assets/images/47.png'
import features from '../assets/images/48.png'
import { FaCheck } from "react-icons/fa6";
import { IoIosPlay } from "react-icons/io";
import { IoHappyOutline } from "react-icons/io5";
import { PiNoteThin } from "react-icons/pi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { BsDiagram3 } from "react-icons/bs";
import { LuFilter } from "react-icons/lu";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

function Locker() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main className='container-fluid main-contact'>
      <div className='row emi-locker'>
        <div className='col-6 locker-title' data-aos="zoom-in-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <h5>We offer Emi Locker <br/>
                Business solutions for <br/>
                Mobile Handsets</h5>
                <h6 className='mt-2 mb-2'>Making EMI-collection an easy, hassle-free process
                for retailers and customers.</h6>
           <div className='d-flex gap-3 align-items-center mt-3 d-none'>
           <button className='try-now'>Try Now</button>
            <p className='d-flex align-items-center gap-2 m-0'><IoIosPlay className='play-video' />Play Video</p>
           </div>
        </div>
        <div className='col-6' data-aos="fade-left" data-aos-anchor="#example-anchor" data-aos-offset="500" data-aos-duration="500">
            <img src={Locker_img}  className='locker-img'/>
        </div>
      </div>
      <div className='row we-are'>
        <div className='col-6 we-are-title' data-aos="zoom-out-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
         <h6>Who We Are</h6>
         <h5>We Are the <br/>
         Acce Global Group</h5>
         <p>At ACCE, we are passionate about technology, service and innovation which
          suit the requirements of our customers as per modern trend. Founded in 2017
         Our evolution is embodied in steady and significant growth that ACCE has
          seen since 2017, ACCE has continued to be India most trusted product
         developer and supplier with multi channel retail experience. The concept
         offers superior experience and customized offerings on latest technology
         related to EMI Locker. We take pride introducing our products under Make
         in India initiative</p>
        <button className='read-more'>Read More</button>
        </div>
        <div className='col-6 d-flex-center' data-aos="zoom-out-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <img src={we_are}  className='we-are-img'/>
        </div>
      </div>
      <div className='row about-emi-locker'>
        <div className='col-12' data-aos="flip-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <h6>Our Values</h6>
            <h5 className='text-start'>About Emi Locker</h5>
        </div>
        <div className='col-4' data-aos="flip-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
           <div className='about-emi'>
              <div className='d-flex-center'>
                  <img src={retailer} />
              </div>
               <h5>For Retailer</h5>
               <p>The entire Process of Activating the App is undertaken by the Retailer. The process is very easy and user friendly, allowing retailers to remotely lock handsets upon non-repayment of EMI.</p>
           </div>
           
        </div>
        <div className='col-4' data-aos="flip-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='about-emi'>
              <div className='d-flex-center'>
               <img src={finance} />
              </div>
                <h5>For Finance</h5>
                <p>Retailers can remotely unlock handsets once the outstanding EMI amount has been cleared by the customer.</p>
            </div>
        </div>
        <div className='col-4' data-aos="flip-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
           <div className='about-emi'>
                <div className='d-flex-center'>
                      <img src={customer} />
                 </div>
                <h5>For Customer</h5>
                <p>Customers can track their lock/unlock status, payments and outstanding balance from within the App.</p>
           </div>
        </div>
      </div>
      <div className='row'>
      <div className='col-12 locker-box' data-aos="fade-up-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='box'>
                <IoHappyOutline className='icon' />
                <h6>232</h6>
                <p>Happy clients</p>
            </div>
            <div className='box'>
                <PiNoteThin className='icon' />
                <h6>521</h6>
                <p>Projects</p>
            </div>
            <div className='box'>
                <MdOutlineSupportAgent className='icon' />
                <h6>1463</h6>
                <p>Hours Of Support</p>
            </div>
            <div className='box'>
                <GrUserWorker className='icon' />
                <h6>15</h6>
                <p>Hard Workers</p>
            </div>
        </div>
      </div>
      <div className='row features-locker'>
      <div className='col-12' data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <h6>Features</h6>
            <h5 className='text-start'>EMI Locker</h5>
      </div>
        <div className='col-5 d-flex justify-content-start' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <img src={features} className='features-img' />
        </div>
        <div className='col-7 d-flex' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='row'>
                <div className='col-6'>
                    <div className='features-box'>
                        <FaCheck className='check-icon' />
                        <p>Easy EMI Financing</p>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='features-box'>
                        <FaCheck className='check-icon' />
                        <p>EMI Activation/Deactivation</p>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='features-box'>
                        <FaCheck className='check-icon' />
                        <p>Emi Purchasing Product</p>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='features-box'>
                        <FaCheck className='check-icon' />
                        <p>Low Interest Product
                           for Customer</p>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='features-box'>
                        <FaCheck className='check-icon' />
                        <p>With Other Benifits 
                          for Customer</p>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='features-box'>
                        <FaCheck className='check-icon' />
                        <p>Security and Privacy
                            with Always</p>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='features-box'>
                        <FaCheck className='check-icon' />
                        <p>Hassle Free Process</p>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='features-box'>
                        <FaCheck className='check-icon' />
                        <p>Ensuring Customer
                        Privacy</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className='row customer-busines'>
      <div className='col-12'>
            <h5 className='text-start' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Benificial for <br/>
            Customer &Business</h5>
      </div>
        <div className='col-4' data-aos="flip-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='busines-box'>
                 <BsDiagram3 className='busines-icon'/>
                <h5>Automation Task for Enterprise on Business Customer</h5>
                <p>All Control the status on Customer</p>
            </div>
        </div>
        <div className='col-4' data-aos="flip-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='busines-box'>
                 <LuFilter className='busines-icon'/>
                <h5>Control the Activation
                 Status</h5>
                                <p>Retailer and Enterprice have both
                 responsable for Active/Deactive</p>
            </div>
        </div>
        <div className='col-4' data-aos="flip-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='busines-box'>
                 <MdOutlineAdminPanelSettings className='busines-icon'/>
                <h5>CAdmin Access</h5>
                <p>In that Case Enterprice and Retailer</p>
            </div>
        </div>
      </div>
      <div className='row about-emi-locker'>
        <div className='col-12'>
            <h6 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Services</h6>
            <h5 className='text-start' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Benificial for <br/> Customer &Business</h5>
        </div>
        <div className='col-4' data-aos="fade-up-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
           <div className='about-emi'>
              <div className='d-flex-center'>
                  <img src={retailer} />
              </div>
               <h5>Get low cost EMI</h5>
               <p>The entire Process of Activating the App is undertaken by the Retailer. The process is very easy and user friendly, allowing retailers to remotely lock handsets upon non-repayment of EMI.</p>
           </div>
           
        </div>
        <div className='col-4' data-aos="fade-up-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='about-emi'>
              <div className='d-flex-center'>
               <img src={finance} />
              </div>
                <h5>Benifiets for Customer</h5>
                <p>Because of the easy EMIs available, customers can buy a better version of the smartphones they want. They can afford to expand their budget and invest in a smartphone from a higher price band because there is no pressing obligation to pay the entire amount upfront.</p>
            </div>
        </div>
        <div className='col-4' data-aos="fade-up-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
           <div className='about-emi'>
                <div className='d-flex-center'>
                      <img src={customer} />
                 </div>
                <h5>Benifiets for Retailers</h5>
                <p>Retailers will have more opportunities to expand their customer base and move their inventory. Additionally, more people would visit their stores. The retailer has complete control over the phone until the full amount is paid</p>
           </div>
        </div>
        <div className='col-4' data-aos="fade-up-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
           <div className='about-emi'>
              <div className='d-flex-center'>
                  <img src={retailer} />
              </div>
               <h5>New to Old Refurbished Phone</h5>
               <p>It is available for both New and
 refurbished handsets.</p>
           </div>
           
        </div>
        <div className='col-4' data-aos="fade-up-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='about-emi'>
              <div className='d-flex-center'>
               <img src={finance} />
              </div>
                <h5>Benifiets for 
Customer</h5>
                <p>All Handsets are come with
 warenty and EMI</p>
            </div>
        </div>
        <div className='col-4' data-aos="fade-up-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
           <div className='about-emi'>
                <div className='d-flex-center'>
                      <img src={customer} />
                 </div>
                <h5>Hassle Free B2BProcess</h5>
                <p>Retailers can directly connect to customers without involving an intermediary</p>
           </div>
        </div>
      </div>
      <div className='row faq-accordion'> 
        <div className='col-12'>
            <h5 className='text-start' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Frequently Asked Questions</h5>
        </div>
        <div className='col-12'>
        <div className="accordion accordion-flush mt-4" id="accordionFlushExample">
            <div className="accordion-item" data-aos="flip-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                1.What is ACCE Finance Lock?
                </button>
              </h2>
              <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">It’s a Finance option for the customers those who are unable to purchase Handsets from organized finance due to obligations from Banks. The retailers would be the Only decision maker for giving this facility to the customers.</div>
              </div>
            </div>
            <div className="accordion-item" data-aos="flip-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                2.How would this ACCE Finance lock Work?
                </button>
              </h2>
              <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">The entire Process of Activating the App would be done by the Retailer. The process is very easy and user friendly for the retailers. Most important requirement for activating this App is The IMEI number and the Activation Key(Provided by ACCE Global).</div>
              </div>
            </div>
            <div className="accordion-item" data-aos="flip-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                3.What if the Customer doesnt pay EMI ?
                </button>
              </h2>
              <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">The retailer will have the rights where he/she would be able to trigger the option for locking the handsets. Once the Lock Handset option is triggered the customer won’t be able to use the handset till the time it is unlocked.</div>
              </div>
            </div>
            <div className="accordion-item" data-aos="flip-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                4.What would be the procedure to Unlock the handsets?
                </button>
              </h2>
              <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">The Unlocking procedure would only again be with the Retailers only. There would be another tab namely EMI Unlock. Once the EMI payment is received the retailer can unlock the handset from his end.</div>
              </div>
            </div>
            <div className="accordion-item" data-aos="flip-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                5.What is this EMI Remove Option?
                </button>
              </h2>
              <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">Once all the agreed EMIs completed from the customer’s end, Retailer will trigger the EMI Remove’ option. The Retailer will no longer have the access to customer’s handset.</div>
              </div>
            </div>
            <div className="accordion-item" data-aos="flip-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                6.Can this Finance Lock be available in any other Gadgets in the Store?
                </button>
              </h2>
              <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">No, this is only for the Handsets as of Now.</div>
              </div>
            </div>
        </div>
        </div>
      </div>
      <div className='row mt-5'>
         <div className='col-xxl-6 col-xl-6 p-50 pe-0 mt-5' data-aos="zoom-in-down" data-aos-offset="400" data-aos-easing="ease-in-sine">
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7894.420536818548!2d88.45918118771108!3d22.621052192396174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89fa843f35141%3A0xf200e4d62cb7d089!2sACCE%20GLOBAL%20SOFTWARE!5e0!3m2!1sen!2sin!4v1713335318014!5m2!1sen!2sin" width="100%" height="690" loading="lazy" ></iframe>
         </div>
         <div className='col-xxl-6 col-xl-6' data-aos="zoom-in-down" data-aos-offset="500" data-aos-easing="ease-in-sine">
         <div className='row'>
           <div className='col-12'>
          <div className='get-touch-title text-center'>
            <h5>Get in Touch</h5>
            <h6>Let's Discuss Your Project Today</h6>
           </div>
          </div>
           <div className='col-12 mt-5'>
            <div className='row d-flex justify-content-center'>
              <div className='col-10 get-boxs'>
              <div className='get-touch'>
              <div className='row'>
                  <div className='col-xxl-6 col-xl-6'>
                    <form className='get-touch-form'>
                       <div className="mb-3">
                       <label htmlFor="exampleInputFullname" className="form-label">Your First Name</label>
                         <input type="text" className="form-control" id="exampleInputFullname" aria-describedby="emailHelp" />
                       </div>
                     </form>
                  </div>
                  <div className='col-xxl-6 col-xl-6'>
                     <form>
                       <div className="mb-3">
                         <label htmlFor="exampleInputHowabout" className="form-label">Your Last Name</label>
                         <input type="text" className="form-control" id="exampleInputHowabout" aria-describedby="emailHelp" />
                       </div>
                     </form>
                  </div>
                  <div className='col-xxl-6 col-xl-6'>
                     <form>
                       <div className="mb-3">
                         <label htmlFor="exampleInputEmail1" className="form-label">Your email address</label>
                         <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                       </div>
                     </form>
                  </div>
                  <div className='col-xxl-6 col-xl-6'>
                    <form>
                       <div className="mb-3">
                         <label htmlFor="examplePhoneNumber" className="form-label">Phone Number</label>
                         <input type="number" className="form-control" id="examplePhoneNumber" aria-describedby="emailHelp" />
                       </div>
                     </form>
                  </div>
                  <div className='col-12'>
                     <form>
                     <div className="mb-3">
                       <label htmlFor="exampleFormControlTextarea1" className="form-label">How can we help you?</label>
                       <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                     </div>
                     </form>
                  </div> 
                  <div className='col-12 d-flex justify-content-center mt-3'>
                    <button className='submit-your-request'>Submit Your Request</button>
                  </div>
                </div>
              </div>
              </div>
            </div>
           </div>
         </div>
         </div>
       </div>
       <div className='row our-newsletter'>
        <div className='col-12' data-aos="zoom-in-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <h5>Our Newsletter</h5>
            <div className='input-btn'>
                <input type='text' placeholder='Type Your Email Address' />
                <button>Subscribe</button>
            </div>
        </div>

       </div>
    </main>
  )
}

export default Locker
