import React, { useState, useEffect } from 'react';
import '../assets/scss/style.css';
// import Logo from '../assets/images/01.png';
import { NavLink } from 'react-router-dom';
import { CgMenuRight } from "react-icons/cg";
import { TiHomeOutline } from "react-icons/ti";
import { LuBadgeInfo } from "react-icons/lu";
import { LiaCommentSolid } from "react-icons/lia";
import { LuContact2 } from "react-icons/lu";
import { GrGallery } from "react-icons/gr";
import { MdOutlineSecurity } from "react-icons/md";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);


    
    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

   const handleClick = () => {
    const sidebarCloseBtn = document.querySelector(".sidebarCloseBtn");
    if (sidebarCloseBtn) {
      sidebarCloseBtn.click();
    }
  };

  return (
    <> 
      <nav className={`main-navbar ${scrolled ? 'scrolled' : ''}`} id="navbar">
        <p className='logo-img'></p>
        <ul className='nav-list'>
          <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/'>Home</NavLink></li>
          <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/about'>About us</NavLink></li>
          {/* <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/pages'>Pages</NavLink></li> */}
          <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/blog'>Blog</NavLink></li>
          <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/contact'>Contact us</NavLink></li>
          <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/gallery'>Gallery</NavLink></li>
          <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/emilocker'>Emi Locker</NavLink></li>
          <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/outlets'>Outlets</NavLink></li>
        </ul>
       
        <CgMenuRight className='menuright' data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" />
      </nav>
      <div className="offcanvas offcanvas-start" data-bs-scroll="true"  id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
        <div className="offcanvas-header">
            <button type="button" className="btn-close offcanvas-close sidebarCloseBtn" aria-label="Close" data-bs-dismiss="offcanvas"></button>
        </div>
        <div className="offcanvas-body">
          <div className='offcanvas-box'>
          <ul className='nav-list'>
             <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/' onClick={handleClick}><TiHomeOutline className='icons' /> Home</NavLink></li>
             <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/about' onClick={handleClick}><LuBadgeInfo className='icons' /> About us</NavLink></li>
             {/* <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/pages'>Pages</NavLink></li> */}
             <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/blog' onClick={handleClick}><LiaCommentSolid className='icons' /> Blog</NavLink></li>
             <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/contact' onClick={handleClick}><LuContact2 className='icons' /> Contact us</NavLink></li>
             <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/gallery' onClick={handleClick}><GrGallery className='icons' /> Gallery</NavLink></li>
             <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/emilocker' onClick={handleClick}><MdOutlineSecurity className='icons' /> Emi Locker</NavLink></li>
             <li><NavLink className={(e) => {return e.isActive?"active": ""}} to='/outlets' onClick={handleClick}><MdOutlineSecurity className='icons' /> Outlets</NavLink></li>
          </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
