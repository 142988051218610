import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { useParams } from 'react-router-dom'; // Assuming you're using React Router

function BlogDetails() {
  const [blog, setBlog] = useState("");
  const { id } = useParams(); // Extracting ID from URL params
  console.log("ID:", id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          // 'Authorization': 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI5MSIsInJvbGVJZCI6NCwicm9sZU5hbWUiOiJSZXRhaWxlciIsImlhdCI6MTcxNTMyNDMwNSwiZXhwIjoxNzQ2ODYwMzA1LCJpc3MiOiJwaG9uZWRla2hvc2VjcmV0aXNzdWVyIn0.KL7ATOjiOm1FqcLX4-JrJvlCJMWSnJ0O574fOdfN4bM'
        }
        const response = await axios.post(`http://api.phonedekho.co.in/v1/customer/blog/getAllBlogs`, { "id": id },{
          headers: headers
        });
        setBlog(response.data.data[0]);
        console.log(blog, "-----blog")
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    console.log(blog, "-----blog")

    fetchData();
  }, [id]); // Adding id to dependency array to refetch data when id changes

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <main className='container-fluid main-contact'>
      <div className='row'>
        <div className='col-12 p-0'>
          <div className='contact-title'>
            <h5>Blog Details</h5>
            <h6>Home <AiOutlineRight /> Blog Details</h6>
          </div>
        </div>
      </div>
      <div className='row main-blogs'>
        <div className='col-12 mt-4'>
          <div className='blogdetails-img'>
            <img src={blog.imageUrl} alt="Blog Cover" />
          </div>
          <div className='blog-details'>
            <div className='title pb-0'>
              <h6>{blog.title}</h6>
              <p>{blog.date}</p>
            </div>
            <div className='sub-title pt-0'>
              <p dangerouslySetInnerHTML={{ __html: blog.article }}></p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default BlogDetails;
