import React, { useContext, useEffect} from 'react'
import { AiOutlineRight } from "react-icons/ai"
import { GoArrowRight } from "react-icons/go";
import {counterContext} from '../context/context';
import { Link } from 'react-router-dom';

function Blog() {

  const data = useContext(counterContext)
  console.log(data)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
      <main className='container-fluid main-contact'>
         <div className='row'>
          <div className='col-12 p-0'>
            <div className='contact-title'>
                <h5  data-aos="fade-right">Blog</h5>
                <h6 data-aos="fade-right">Home <AiOutlineRight /> Blog</h6>
            </div>
          </div>
         </div>
        <div className='row main-blogs'>
       {data.map((item, index) => (
          <div className='col-xxl-4 col-xl-4 col-lg-6  mt-4' key={index}>
          <div className='blog-img'>
            <img src={item.imageUrl} />
          </div>
          <div className='blog-details'>
            <div className='title pb-0'>
               <h6>{item.title}</h6>
               <p>{item.date}</p>
            </div>
            <div className='sub-title pt-0'>
                <p dangerouslySetInnerHTML={{ __html: item.article}}></p>
                <Link to={`/blog/${item.id}`}>Read More <GoArrowRight className='right-arrow' /></Link>
            </div>
          </div>
          </div>
        ))}
        </div>
      </main>
  )
}

export default Blog
