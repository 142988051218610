import React, { useContext, useEffect, useState} from 'react';
import best_renewed from  '../assets/images/07.png';
import fast_process from  '../assets/images/08.png';
import control_policy from  '../assets/images/09.png';
import save_money from  '../assets/images/10.png';
import user_img from  '../assets/images/11.png';
import food from  '../assets/images/12.png';
import development from  '../assets/images/14.png';
import mobile from  '../assets/images/13.png';
import ecommerce from  '../assets/images/15.png';
import app from  '../assets/images/16.png';
import mission from  '../assets/images/21.png';
import goal from  '../assets/images/22.png';
import vision from  '../assets/images/23.png';
import adapt from  '../assets/images/24.png';
import listen from  '../assets/images/25.png';
import empower from  '../assets/images/26.png';
import deliver from  '../assets/images/27.png';
import phonedekho from  '../assets/images/28.png';
import acceTech from  '../assets/images/29.png';
import locker from  '../assets/images/30.png';
import security from  '../assets/images/31.png';
import amazon from  '../assets/images/59.webp';
import flipcart from  '../assets/images/60.webp';
import buy_sell from  '../assets/images/61.png';
// import user from  '../assets/images/62.png';
import news1 from  '../assets/images/news/1.png';
import news2 from  '../assets/images/news/2.png';
import news3 from  '../assets/images/news/3.png';
import news4 from  '../assets/images/news/04.png';
import news5 from  '../assets/images/news/5.png';
import news6 from  '../assets/images/news/6.png';

import { GrFormNext } from "react-icons/gr";
import { FcAlarmClock } from "react-icons/fc";
import { FcFlowChart } from "react-icons/fc";
import { FcInspection } from "react-icons/fc";
import { FcFilingCabinet } from "react-icons/fc";
import { FcPlus } from "react-icons/fc";
import { FcFilmReel } from "react-icons/fc";
import { GoArrowRight } from "react-icons/go";
import { FcStatistics } from "react-icons/fc";
import { FcBarChart } from "react-icons/fc";
import Slider from "react-slick";
import { FaStar } from "react-icons/fa6";
import {counterContext} from '../context/context';
import { IoStar } from "react-icons/io5";
import { BsStarHalf } from "react-icons/bs";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { IoIosArrowForward } from "react-icons/io";
import { NavLink } from 'react-router-dom';
import axios from 'axios';

function Main() { 

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState({
    success: false,
    error: false,
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Additional data fields
    const deviceType = 'web';
    const fcmToken = 'dummy-token';

    // Merging formData with additional fields
    const payload = {
      ...formData,
      deviceType,
      fcmToken
    };

    try {
      const response = await axios({
        method: 'POST',
        url: 'http://api.phonedekho.co.in/v1/customerQuery/inserCustomerQuery',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(payload),
      });

      console.log('Response data:', response.data);
      setFormStatus({
        success: true,
        error: false,
        message: 'Your request has been submitted successfully!'
      });

      // Hide the success message after 2 seconds
      setTimeout(() => {
        setFormStatus({
          success: false,
          error: false,
          message: ''
        });
      }, 2000); // 2000ms = 2 seconds

      // Reset form data after successful submission
      setFormData({
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      console.error('Error:', error);
      setFormStatus({
        success: false,
        error: true,
        message: 'There was an error submitting your request. Please try again later.'
      });
    }
  };


  const data = useContext(counterContext);

  useEffect(() => {
    AOS.init({});
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1098,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 672,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };

  var feedback  = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 928,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
        }
      },
    ]
  };

  return (
    <main className='main-container'>
     <div className='container-fluid'>  
       <div className='row renewed-experts'>
        <div className='col-12 d-flex justify-content-center align-items-center'>
            <div className='renewed-title'>
                  <h5 data-aos="fade-right" data-aos-offset="300"
            data-aos-easing="ease-in-sine">Our Team of Experts<br/>
                  work with their expertise</h5>
                <p data-aos="fade-right" data-aos-offset="400"
            data-aos-easing="ease-in-sine">We take pride in introducing our products as part of <br />
                    the “Make in India” initiative.</p>
              <div className='get-started'>
                <button data-aos="fade-right">Get Started</button>
              </div>
            </div>
        </div>
       </div>
       <div className='row'>
        <div className='col-12'>
          <div className='our-product pb-0'>
            <h6  data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">Our Products and Services</h6>
            <h5 data-aos="fade-right"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine">Introducing Our <br />
            Revolutionary Products
            </h5>
          </div>
        </div>
        <div className='col-12'>
          <div className='row trusted-by'>
          <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6' data-aos="zoom-in"  data-aos-offset="300" data-aos-easing="ease-in-sine">
          <div className='trusted-by-top mt-2 mb-2'>
            <div className='trusted-img'>
              <img src={phonedekho} className='img-fluid'/>
            </div>
            <a href='https://phonedekho.co.in/' target="_blank"><GrFormNext className='trusted-next-icon' /></a>
            <div className='trusted-title'>
              <h4>Phonedekho</h4>
              <p className='m-0'>Discover the Perfect Phone with PhoneDekho: Your Ultimate Smartphone Companion</p>
            </div>
          </div>
          </div>
          <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6' data-aos="zoom-in"  data-aos-offset="400" data-aos-easing="ease-in-sine">
          <div className='trusted-by-top mt-2 mb-2'>
            <div className='trusted-img'>
              <img src={acceTech} className='img-fluid'/>
            </div>
            <a href='https://www.accetech.in/' target="_blank"><GrFormNext className='trusted-next-icon' /></a>
            <div className='trusted-title'>
              <h4>AcceTech</h4>
              <p className='m-0'>We offering software & mobile 
              application development on various platforms. </p>
            </div>
          </div>
          </div>
          <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6' data-aos="zoom-in"  data-aos-offset="500" data-aos-easing="ease-in-sine">
          <div className='trusted-by-top mt-2 mb-2'>
            <div className='trusted-img'>
              <img src={locker} className='img-fluid'/>
            </div>
            {/* <a href='/emilocker'><GrFormNext className='trusted-next-icon' /></a> */}
            <NavLink className={(e) => {return e.isActive?"active": ""}} to='/emilocker'><GrFormNext className='trusted-next-icon' /></NavLink>
            <div className='trusted-title'>
              <h4>EMI Locker- Mobile app</h4>
              <p className='m-0'>Making EMI-collection an easy, 
               hassle-free process for retailers and customers.</p>
            </div>
          </div>   
          </div>
          <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6' data-aos="zoom-in"  data-aos-offset="600" data-aos-easing="ease-in-sine">
          <  div className='trusted-by-top mt-2 mb-2'>
              <div className='trusted-img'>
                <img src={security} className='img-fluid'/> 
              </div>
              <a href='https://acceantitheftpro.in/' target="_blank"><GrFormNext className='trusted-next-icon' /></a>
              <div className='trusted-title'>
                <h4>Data Security app</h4>
                <p className='m-0'>To safeguard your privacy and secure your phone from intruders ACCE ANTI THEFT PRO is prudent</p>
              </div>
            </div>
          </div>  
          </div>
        </div> 
       </div> 
       <div className='row happy-clients' data-aos="zoom-in-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
       <div className='col-6 client' data-aos="fade-right" data-aos-offset="300"  data-aos-easing="ease-in-sine">
        <h6>1.5+ lakhs Unit sold</h6>
        <p>Crossing the remarkable milestone of selling over 150,000 <br/> 
        units of our product ! This accomplishment stands as a testament to <br/> 
        our dedication, innovation, and unwavering commitment to excellence.</p>
       </div>
       <div className='col-6 ps-5 d-flex align-items-center' data-aos="fade-right" data-aos-offset="300"  data-aos-easing="ease-in-sine">
         <div className='d-flex gap-5 ratings-box'>
          <div className='ratings' >
            <h1>5000+</h1>
            <h6>Pan India retailers</h6>
          </div>
          <div className='ratings' >
            <h5 className='d-flex justify-content-start'>4.5</h5>
            <h6>Ratings</h6>
            <div className='d-flex gap-2 align-items-center'>
            <IoStar className='star-fill' />
            <IoStar className='star-fill' />
            <IoStar className='star-fill' />
            <IoStar className='star-fill' />
            <BsStarHalf className='star-fill fs-3' />
            </div>
          </div>
          </div>
         </div>
        </div>
       <div className='row'>
        <div className='col-12'>
        <div className='our-product'>
            <h6 data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">Our Visionary Path</h6>
            <h5 data-aos="fade-right"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine">Our Journey  <br />
            Beyond Boundaries
            </h5>
          </div>
        </div>
        <div className='col-12 '>
         <div className='d-flex gap-5 justify-content-center mt-5 vision-goal'>
         <div className='beyond-boundarie' data-aos="fade-down-right"  data-aos-offset="300" data-aos-easing="ease-in-sine">
            <img src={mission} />
            <h4>Our Mission</h4>
            <p>Our mission is to extend the life of
            mobile devices, reduce electronic
            waste, and deliver quality renewed 
            phones to our customers.</p>
          </div>
          <div className='beyond-boundarie' data-aos="flip-left"  data-aos-offset="400" data-aos-easing="ease-in-sine">
            <img src={vision} />
            <h4>Our Vision</h4>
            <p>Our mission is to extend the life of
            mobile devices, reduce electronic
            waste, and deliver quality renewed 
            phones to our customers.</p>
          </div>
          <div className='beyond-boundarie' data-aos="fade-down-left"  data-aos-offset="500" data-aos-easing="ease-in-sine">
            <img src={goal} />
            <h4>Our Goal</h4>
            <p>Our mission is to extend the life of
            mobile devices, reduce electronic
            waste, and deliver quality renewed 
            phones to our customers.</p>
          </div>
         </div>
        </div>
       </div>
       <div className='row'>
        <div className='col-12'>
          <div className='our-product'>
            <h6 data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">Our Services</h6>
            <h5 data-aos="fade-right"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine">The sum of services <br/> 
             we provide
            </h5>
          </div>
        </div>
        <div className='col-12'>
          <div className='row trusted-by'>
          <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6' data-aos="zoom-in-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <div className='trusted-by-top our-services mt-3 mb-2'>
            <div className='trusted-img'>
              <img src={development} className='img-fluid'/>
            </div>
            <div className='trusted-title'>
              <h4 className='text-center'> Web Development</h4>
            </div>
          </div>
          </div>
          <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6' data-aos="zoom-in-right" data-aos-offset="400" data-aos-easing="ease-in-sine">
          <div className='trusted-by-top our-services mt-3 mb-2'>
            <div className='trusted-img'>
              <img src={mobile} className='img-fluid'/>
            </div>
            <div className='trusted-title'>
              <h4 className='text-center'>Mobile Technician</h4>
            </div>
          </div>
          </div>
          <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6' data-aos="zoom-in-right" data-aos-offset="500" data-aos-easing="ease-in-sine">
          <div className='trusted-by-top our-services mt-3 mb-2'>
            <div className='trusted-img'>
              <img src={app} className='img-fluid'/>
            </div>
            <div className='trusted-title'>
              <h4 className='text-center'>App Development</h4>
            </div>
          </div>
          </div>
          <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6' data-aos="zoom-in-right" data-aos-offset="600" data-aos-easing="ease-in-sine">
          <div className='trusted-by-top our-services mt-3 mb-2'>
            <div className='trusted-img'>
              <img src={ecommerce} className='img-fluid'/>
            </div>
            <div className='trusted-title'>
              <h4 className='text-center'>E-Commerce Solution</h4>
            </div>
          </div>
          </div>
          </div>
        </div>
       </div>
       <div className='row'>
        <div className='col-12'>
          <div className='our-product'>
            <h6 data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">Our Services</h6>
            <h5 data-aos="fade-right"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine">We Provide Facility <br/> 
            for Our employee
            </h5>
          </div>
        </div>
        <div className='col-12'>
          <div className='row trusted-by facility-employee'>
           <div className='col-xxl-4 col-xl-6 col-lg-6 mt-4 mb-3' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
           <div className='provide-facility'>
            <FcAlarmClock className='employee' />
             <p>Working Hours & Time Off</p>
           </div>
           </div>
           <div className='col-xxl-4 col-xl-6 col-lg-6 mt-4 mb-3' data-aos="fade-right" data-aos-offset="400" data-aos-easing="ease-in-sine">
            <div className='provide-facility'>
            <FcFlowChart className='employee' />
             <p>Gifts & Activities</p>
            </div>
           </div>
           <div className='col-xxl-4 col-xl-6 col-lg-6 mt-4 mb-3' data-aos="fade-right" data-aos-offset="500" data-aos-easing="ease-in-sine">
            <div className='provide-facility'>
            <FcInspection className='employee' />
             <p>Insurance</p>
            </div>
           </div>
           <div className='col-xxl-4 col-xl-6 col-lg-6 mt-4 mb-3' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='provide-facility'>
            <FcFilingCabinet className='employee' />
             <p>Skill Development</p>
            </div>
           </div>
           <div className='col-xxl-4 col-xl-6 col-lg-6 mt-4 mb-3' data-aos="fade-right" data-aos-offset="400" data-aos-easing="ease-in-sine">
            <div className='provide-facility'>
            <FcPlus className='employee' />
             <p>Healthcare
             </p>
            </div>
           </div>
           <div className='col-xxl-4 col-xl-6 col-lg-6 mt-4 mb-3' data-aos="fade-right" data-aos-offset="500" data-aos-easing="ease-in-sine">
            <div className='provide-facility'>
            <FcStatistics className='employee' />
             <p>Financial Growth</p>
            </div>
           </div>
           <div className='col-xxl-4 col-xl-6 col-lg-6 mt-4 mb-3' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='provide-facility'>
              <div className='employee'>
              <img src={food}  className='h-100'/>
              </div>
             <p>Food & Beverages
             </p>
            </div>
           </div>
           <div className='col-xxl-4 col-xl-6 col-lg-6 mt-4 mb-3' data-aos="fade-right" data-aos-offset="400" data-aos-easing="ease-in-sine">
            <div className='provide-facility'>
            <FcFilmReel className='employee' />
             <p>Wellness</p>
            </div>
           </div>
           <div className='col-xxl-4 col-xl-6 col-lg-6 mt-4 mb-3' data-aos="fade-right" data-aos-offset="500" data-aos-easing="ease-in-sine">
            <div className='provide-facility'>
            <FcBarChart className='employee' />
             <p>Work Life Balance</p>
            </div>
           </div>
          </div>
        </div>
       </div>
       <div className='row'>
        <div className='col-12'>
          <div className='our-product'>
            <h6 data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">Our Value</h6>
            <h5 data-aos="fade-right"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine">Embracing Excellence, <br />
                Unveiling Our Core Values
            </h5>
          </div>
        </div>
        <div className='col-12'>
        <div className='d-flex gap-5 justify-content-center embracing'>
          <div className='our-core-values' data-aos="zoom-out-down" data-aos-offset="400" data-aos-easing="ease-in-sine">
            <img src={listen} />
            <h4>Listen</h4>
            <p>We listen to understand our customers and our Team.</p>
          </div>
          <div className='our-core-values' data-aos="zoom-out-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <img src={empower} />
            <h4>Empower</h4>
            <p>We empower our team to succeed with honesty.</p>
          </div>
          <div className='our-core-values' data-aos="zoom-out-down" data-aos-offset="200" data-aos-easing="ease-in-sine">
            <img src={adapt} />
            <h4>Adapt</h4>
            <p>We constantly adapt to stay updated to market requirements.</p>
          </div>
          <div className='our-core-values' data-aos="zoom-out-down" data-aos-offset="100" data-aos-easing="ease-in-sine">
            <img src={deliver} />
            <h4>Deliver</h4>
            <p>Delivering the quality with punctuality.</p>
          </div>
         </div>
        </div>
       </div>
       <div className="row our-partners justify-content-center">
        <div className="col-12 text-center">
            <h6 className="main-title" data-aos="fade-right" data-aos-offset="300"
            data-aos-easing="ease-in-sine">Our Partners</h6>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <a href="#">
                <img src={amazon} alt="Alternate Text" />
            </a>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <a href="#">
                <img src={flipcart} alt="Alternate Text" />
            </a>
        </div>
       </div>
       <div className='row' data-aos="flip-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div className='col-12 p-0 mt-4'>
          <img src={buy_sell} className='img-fluid' /> 
        </div>
       </div>
       <div className='row'>
       <div className='col-12'>
          <div className='our-product'>
            <h6 data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">We Featured in</h6>
            <h5 data-aos="fade-right"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine">Embracing Excellence, <br /> Unveiling Our Core Values
            </h5>
          </div>
        </div>
        <div className='col-12 featured-in'>
          <div className='row'>
            <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6' data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <div className='featured-box'>
                <img src={news1} className='img-fluid' />
                <a href='https://theprint.in/ani-press-releases/phonedekho-co-ins-website-launch-marks-a-turning-point-in-indian-smartphone-re-commerce/2065320/' target="_blank">Read More <IoIosArrowForward /></a>
              </div>
            </div>
            <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6' data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <div className='featured-box'>
                <img src={news5} className='img-fluid news2' />
                <a href='https://pnn.digital/phonedekho-co-ins-website-launch-marks-a-turning-point-in-indian-smartphone-re-commerce/' target="_blank">Read More <IoIosArrowForward /></a>
              </div>
            </div>
            <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6' data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <div className='featured-box'>
                <img src={news2} className='img-fluid' />
                <a href='https://aninews.in/news/business/phonedekhocoins-website-launch-marks-a-turning-point-in-indian-smartphone-re-commerce20240501165352/' target="_blank">Read More <IoIosArrowForward /></a>
              </div>
            </div>
            <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6' data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <div className='featured-box'>
                <img src={news3} className='img-fluid' />
                <a href='https://www.lokmattimes.com/business/phonedekhocoins-website-launch-marks-a-turning-point-in-indian-smartphone-re-commerce/' target="_blank">Read More <IoIosArrowForward /></a>
              </div>
            </div>
            <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6' data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <div className='featured-box'>
                <img src={news4} className='img-fluid' />
                <a href='https://www.msn.com/en-xl/news/other/phonedekho-co-in-s-website-launch-marks-a-turning-point-in-indian-smartphone-re-commerce/ar-AA1nZ17N' target="_blank">Read More <IoIosArrowForward /></a>
              </div>
            </div>
            <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6' data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <div className='featured-box'>
                <img src={news6} className='img-fluid' />
                <a href='https://firstindia.co.in/news/press-releases/phonedekhocoin-poised-to-revolutionise-smartphone-re-commerce-in-india' target="_blank">Read More <IoIosArrowForward /></a>
              </div>
            </div>
          </div>
        </div>
       </div>
       <div className='row'>
        <div className='col-12'>
          <div className='our-product'>
            <h6 data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">Why Choose Us</h6>
            <h5 data-aos="fade-right"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine">Better Solutions For <br />
                Renewed Smartphone
            </h5>
          </div>
        </div>
        <div className='col-12'>
          <div className='row trusted-by'>
            <div className='col-6'>
              <div className='renewed-smartphone mt-4 mb-4' data-aos="fade-down-right" data-aos-offset="200" data-aos-easing="ease-in-sine">
               <div className='employee'>   
                <img src={fast_process} className='h-100' />
               </div>
               <div className='better-solutions'>
                <h4>Quality Assurance:</h4>
                <p>Our refurbished smartphones undergo meticulous testing and restoration processes, ensuring top-notch quality and reliability. 
                  Trust in devices that perform like new, at a fraction of the cost.</p>
               </div>
              </div>
              <div className='renewed-smartphone mt-4 mb-4' data-aos="fade-down-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
               <div  className='employee'>
                <img src={save_money} className='h-100'  />
               </div>
               <div className='better-solutions'>
                <h4>Unbeatable Value:</h4>
                <p>Enjoy premium smartphones at competitive prices. We offer budget-friendly solutions without compromising on performance, giving you the best bang for your buck.</p>
               </div>
              </div>
              <div className='renewed-smartphone mt-4 mb-4' data-aos="fade-down-right" data-aos-offset="400" data-aos-easing="ease-in-sine">
               <div  className='employee'>
                <img src={control_policy} className='h-100'  />
               </div>
               <div className='better-solutions'>
                <h4>Complete Software Support:</h4>
                <p>Beyond hardware, we provide comprehensive software solutions to optimize your device's functionality. From productivity apps to security features, we've got you covered for a seamless smartphone experience.</p>
               </div>
              </div>
            </div>
            <div className='col-6 d-flex justify-content-center best-renewed' data-aos="fade-down-left" data-aos-offset="300" data-aos-easing="ease-in-sine" >
             <img src={best_renewed} className='img-fluid'style={{height: 500 }} />
            </div>
          </div>
        </div>
       </div>
       <div className='row'>
        <div className='col-12'>
          <div className='our-product'>
            <h6 data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">Latest News & Blog</h6>
            <h5 data-aos="fade-right"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine">Read Our <br />
            Latest Articles
            </h5>
          </div>
        </div>
        <div className='col-12' data-aos="flip-left" data-aos-offset="100" data-aos-easing="ease-in-sine">
        <Slider {...settings} className='latest-slick'>
        {data.map((post) => (
          <div className='box-blog' key={post.id} data-aos="flip-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <div className='latest-blogs'>
              <div className='user-information'>
              <p className='dec'>{post.date}</p>
                 <h4 className='title'>{post.title}</h4>
                 <p className='sub-title' dangerouslySetInnerHTML={{ __html: post.article }}></p>
              </div>
              <div className='user-profile mt-3'>
               <div className='d-flex align-items-center gap-2'>
               </div>
                <a src="" className='read-more'>Read More  <GoArrowRight className='right-arrow' /></a>
              </div>
             </div>
          </div>
        ))}
         </Slider>
        </div>
       </div>
       <div className='row'>
       <div className='col-12'>
          <div className='our-product'>
              <h6 data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">Clients Testimonials</h6>
              <h5 data-aos="fade-right"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine">Amazing Feedback  <br />
              Say About Services
            </h5>
          </div>
        </div>
       <div className='col-12 feedback-img'>
        <div className=' row d-flex justify-content-center'>
        <div className='col-10 pt-5 feedback-box' data-aos="zoom-in-up" data-aos-offset="300"  data-aos-easing="ease-in-sine">
        <Slider {...feedback} className='latest-slick p-50'>
          <div className='box-blog'>
           <div className='latest-blogs'>
             <div className='amazing-feedback'>
             <div className='d-flex justify-content-between'>
             <div className='d-flex gap-3 align-items-center'>
             <img src={user_img} className='user_img' />
              <div className='feedback-title'>
                <h4>Sachin Pandey</h4>
                <p>Selfie Enterprice</p>
              </div>
             </div>
                <div className='feedback'>
                 <h4>Retailer</h4>
                 <div className='d-flex gap-1'>
                    <FaStar className='star' />
                    <FaStar className='star' />
                    <FaStar className='star' />
                    <FaStar className='star' />
                    <FaStar className='star' />
                 </div>
                </div>
             </div>
             <div className='mt-5'>
              <p className='blogs-subtitle'>“Selling refurbished phones from [Your Company Name] has been a win-win for us. The devices are in pristine condition, and the competitive pricing helps us stay ahead in the market. Exceptional service and support make them our go-to supplier.”</p>
             </div>
             </div>
           </div>
          </div>
          <div className='box-blog'>
           <div className='latest-blogs'>
             <div className='amazing-feedback'>
             <div className='d-flex justify-content-between'>
             <div className='d-flex gap-3 align-items-center'>
             <img src={user_img} className='user_img' />
              <div className='feedback-title'>
                <h4>Nirav Shah</h4>
                <p>Murugan Enterprise</p>
              </div>
             </div>
                <div className='feedback'>
                 <h4>Retailer</h4>
                 <div className='d-flex gap-1'>
                    <FaStar className='star' />
                    <FaStar className='star' />
                    <FaStar className='star' />
                    <FaStar className='star' />
                    <FaStar className='star' />
                 </div>
                </div>
             </div>
             <div className='mt-5'>
              <p className='blogs-subtitle'>“Highly recommend [Your Company Name] for refurbished mobile phones. The products exceed expectations, and the variety suits every customer's needs. Partnering with them has boosted our sales and customer satisfaction.”</p>
             </div>
             </div>
           </div>
          </div>
          <div className='box-blog'>
           <div className='latest-blogs'>
             <div className='amazing-feedback'>
             <div className='d-flex justify-content-between'>
             <div className='d-flex gap-3 align-items-center'>
             <img src={user_img} className='user_img' />
              <div className='feedback-title'>
                <h4>Arjun Panikkar</h4>
                <p>Star Connect</p>
              </div>
             </div>
                <div className='feedback'>
                 <h4>Retailer</h4>
                 <div className='d-flex gap-1'>
                    <FaStar className='star' />
                    <FaStar className='star' />
                    <FaStar className='star' />
                    <FaStar className='star' />
                    <FaStar className='star' />
                 </div>
                </div>
             </div>
             <div className='mt-5'>
              <p className='blogs-subtitle'>“Impressed with the quality and affordability of [Your Company Name]'s refurbished mobile phones. Our customers love the like-new condition and savings they get, making them repeat buyers. A fantastic partnership!.”</p>
             </div>
             </div>
           </div>
          </div>
         </Slider>
        </div>
        </div>
       </div>
       </div>
           <div className="row">
          <div className="col-12">
           
          </div>
          <div className="col-12 mt-5">
            <div className="row d-flex justify-content-center">
            <div className='col-xxl-6 col-xl-6 p-50 pe-0 mt-5' data-aos="zoom-in-down" data-aos-offset="400" data-aos-easing="ease-in-sine">
             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7894.420536818548!2d88.45918118771108!3d22.621052192396174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89fa843f35141%3A0xf200e4d62cb7d089!2sACCE%20GLOBAL%20SOFTWARE!5e0!3m2!1sen!2sin!4v1713335318014!5m2!1sen!2sin" width="100%" height="690" loading="lazy" ></iframe>
             </div>
              <div className="col-xxl-6 col-xl-6 get-boxs d-flex flex-column justify-content-center">
              <div className="get-touch-title text-center">
                 <h5>Get in Touch</h5>
                 <h6>Let's Discuss Your Project Today</h6>
              </div>
                <div className="get-touch mt-4 m-4">
                <div className="">
                      <form className="get-touch-form row" onSubmit={handleSubmit}>
                       <div className="col-xxl-6 col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                       </div>
               
                       <div className="col-xxl-6 col-xl-6">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
                       </div>
               
                       <div className="col-xxl-6 col-xl-6">
          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
            <input
              type="number"
              className="form-control"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
                       </div>
               
                       <div className="col-xxl-6 col-xl-6">
          <div className="mb-3">
            <label htmlFor="subject" className="form-label">Subject</label>
            <input
              type="text"
              className="form-control"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
                       </div>
               
                       <div className="col-12">
          <div className="mb-3">
            <label htmlFor="message" className="form-label">How can we help you?</label>
            <textarea
              className="form-control"
              id="message"
              rows="3"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
                       </div>
               
                       <div className="col-12 d-flex justify-content-center mt-3">
          <button className="submit-your-request" type="submit">
            Submit Your Request
          </button>
                       </div>
                      </form>

                 {/* Display Success/Failure Message */}
                 {formStatus.success && (
                   <div className="alert alert-success mt-3" role="alert">
                     {formStatus.message}
                   </div>
                 )}
                 {formStatus.error && (
                   <div className="alert alert-danger mt-3" role="alert">
                     {formStatus.message}
                   </div>
                 )}
                 </div>
            </div>
          </div>
           </div>
      </div>
         </div>
      </div>   
  </main>
  )
}

export default Main 