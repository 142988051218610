import './App.css';
import Contact from './components/Contact';
import Main from "./components/main";
import { createBrowserRouter , RouterProvider } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './components/About';
import Blog from './components/Blog';
import BlogDetails from './components/BlogDetails';
import Locker from './components/Locker';
import Gallery from './components/Gallery';
import Outlets from './components/Outlets';
import 'animate.css';
import Careers from './components/Careers';

function App({data}) {

  const router = createBrowserRouter([
    {
      path: "/" ,
      element: <><Navbar/><Main /><Footer/></>
    },
    {
      path: "/contact" ,
      element: <><Navbar/><Contact /><Footer/></>
    },
    {
      path: "/about" ,
      element: <><Navbar/><About /><Footer/></>
    },
    {
      path: "/blog" ,
      element: <><Navbar/><Blog /><Footer/></>
    },
    {
      path: "/blog/:id" ,
      element: <><Navbar/><BlogDetails /><Footer/></>
    },
    {
      path: "/emilocker" ,
      element: <><Navbar/><Locker /><Footer/></>
    },
    {
      path: "/gallery" ,
      element: <><Navbar/><Gallery /><Footer/></>
    },
    {
      path: "/outlets" ,
      element: <><Navbar/><Outlets /><Footer/></>
    },
    {
      path: "/careers" ,
      element: <><Navbar/><Careers /><Footer/></>
    },
  ])
  return (
    <div className="App home-container">
        <RouterProvider router={router} /> 
    </div>
  );
}

export default App;
