import React, { useEffect } from 'react'
import { AiOutlineRight } from "react-icons/ai";
import User from  '../assets/images/03.png';
import { BsFillPlayFill } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { GoArrowRight } from "react-icons/go";
import future from  '../assets/images/33.png';
import kaushik from  '../assets/images/34.png';
import rozmana from  '../assets/images/35.png';
import mithun from  '../assets/images/36.png';
import subodh from  '../assets/images/37.png';
import manab from  '../assets/images/38.png';
import buy from  '../assets/images/40.png';
import sell from  '../assets/images/41.png';
import repair from  '../assets/images/42.png';
import indrajit from  '../assets/images/39.png';
import { TbUserStar } from "react-icons/tb";
import { TbUsers } from "react-icons/tb";
import { TbStars } from "react-icons/tb";
import { MdOutlineWorkHistory } from "react-icons/md";
import { LiaAwardSolid } from "react-icons/lia";
import { PiSealCheckLight } from "react-icons/pi";
import { MdOutlineSettings } from "react-icons/md";
import { IoPricetagsOutline } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import AOS from 'aos';


function About() {

  useEffect(() => {
    AOS.init({});
    window.scrollTo(0, 0) 
  }, []);

  return (

    <main className='container-fluid main-contact'>
      
     <div className='row'>
       <div className='col-12 p-0'>
         <div className='contact-title'>
             <h5 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">About</h5>
             <h6 data-aos="fade-right" data-aos-offset="400"
            data-aos-easing="ease-in-sine">Home <AiOutlineRight /> About</h6>
         </div>
       </div>
     </div>
     <div className='row'>
        <div className='col-12 working-experience'>
          <h6 data-aos="fade-up-right" data-aos-offset="400" data-aos-easing="ease-in-sine"><p className='text-end m-0'>7+ Years Of</p>
            Working Experience In <br/> 
            Quality Repair Services</h6>   
            <p className='m-0' data-aos="fade-up-right" data-aos-offset="400" data-aos-easing="ease-in-sine">Since our inception in 2017, we've thrived as a leading refurbished<br/>
            mobile phone company, witnessing steady growth. Our commitment to <br/>
            excellence is matched only by our dedication to providing top-notch<br/>
            customer service. With a focus on innovation and integrity, we continue<br/>
            to redefine the refurbished mobile phone market, offering quality products<br/>
            tailored to meet the evolving needs of our customers.</p>
        </div>
        <div className='col-12 d-flex mt-5 mb-5 gap-5 justify-content-center align-items-center flex-wrap'>
          <div className='quality-expert' data-aos="flip-left" data-aos-offset="400" data-aos-easing="ease-in-sine">
             <PiSealCheckLight className='quality-icon' />
             <div className='expert-text'>
              <h6>Quality</h6>
              <p>Service</p>
             </div>
          </div>
          <div className='quality-expert' data-aos="flip-left" data-aos-offset="400" data-aos-easing="ease-in-sine">
             <MdOutlineSettings  className='quality-icon' />
             <div className='expert-text'>
              <h6>Expert</h6>
              <p>Engineers</p>
             </div>
          </div>
          <div className='quality-expert' data-aos="flip-left" data-aos-offset="400" data-aos-easing="ease-in-sine">
             <IoPricetagsOutline  className='quality-icon' />
             <div className='expert-text'>
              <h6>Fair</h6>
              <p>Pricing</p>
             </div>
          </div>
          <div className='quality-expert' data-aos="flip-left" data-aos-offset="400" data-aos-easing="ease-in-sine">
             <TbTruckDelivery  className='quality-icon' />
             <div className='expert-text'>
              <h6>Quick</h6>
              <p>Delivery</p>
             </div>
          </div>
        </div>
      </div>
      <div className='row'>
      <div className='col-12'>
          <h6 className='text-center' data-aos="fade-right" data-aos-offset="300"
            data-aos-easing="ease-in-sine">Processes</h6>
          <h5 data-aos="fade-right" data-aos-offset="400"
            data-aos-easing="ease-in-sine">Three Steps Solution</h5>
      </div>
      <div className='col-12 d-flex mt-5 mb-5 gap-5 justify-content-center align-items-center ps-5 pe-5'>
        <div className='row'>
             <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 mt-3 mb-3' data-aos="zoom-in" data-aos-offset="400" data-aos-easing="ease-in-sine">
          <div className='steps-solution'>
             <img src={buy} className='img-fluid steps-solution-icons' />
            <div className='expert-text'>
              <h6>Buy</h6>
              <p>Unlock the value in your old device with our hassle-free buying process. Get top dollar for your used mobile phone today</p>
            </div>
          </div>
             </div>
             <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 mt-3 mb-3' data-aos="zoom-in-up" data-aos-offset="400" data-aos-easing="ease-in-sine">
          <div className='steps-solution'>
             <img src={sell} className='img-fluid steps-solution-icons' />
            <div className='expert-text'>
              <h6>Sell</h6>
              <p>Discover the perfect refurbished phone at unbeatable prices. Explore our extensive selection of quality devices, backed by our satisfaction guarantee.</p>
            </div>
          </div>
             </div>
             <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-12 mt-3 mb-3' data-aos="zoom-in-down" data-aos-offset="400" data-aos-easing="ease-in-sine">
          <div className='steps-solution'>
             <img src={repair} className='img-fluid steps-solution-icons' />
            <div className='expert-text'>
              <h6>Repair</h6>
              <p>Trust our skilled technicians to breathe new life into your damaged phone. Fast, reliable repairs that restore your device to peak performance.</p>
            </div>
          </div>
             </div>
        </div>
        </div>
      </div>
     {/* <div className='row about-company'>
        <div className='col-5 d-flex-center'>
          <img src={User} className='img-fluid company-img' />
        </div>
        <div className='col-7 customer-about'>
          <div className='title-About'>
             <h6>About Our Company</h6>
             <h5 className='text-start'>Future proof your <br />
                business with solutions</h5>
                <p>State burst think end are its. Arrived off she elderly beloved him affix ed <br/>
                noisier yet. Course regard to up he hardly elder noisier.</p>
          </div>
          <div className='customer-support'>
            <div className='workflow mt-3'>
            <BsFillPlayFill  className='icon-play'/>
              <div>
              <h4>Customizable workflow</h4>
                 <p>Codetasker is the leading Shopify expert team and hub for <br/>
                  growing Shopify brands to manage short-term projects</p>
              </div>
            </div>
            <div className='workflow'>
            <BiSupport  className='icon-support'/>
              <div>
              <h4>24x7 Customer support</h4>
                 <p>Codetasker is the leading Shopify expert team and hub for <br/>
                  growing Shopify brands to manage short-term projects</p>
              </div>
            </div>
          </div>
        </div>  
     </div> */}   
     {/* <div className='row help-build'>
        <div className='col-6 d-flex align-items-center'>
            <div className='better-future' data-aos="zoom-in-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <h6>Why Choose Us</h6>
                <h5 className='text-start'>We Help you to Build <br />
                for Better Future</h5>
                <p>Sit amet consectetur adiscin varius montes massa <br />
                   blandit orci. Sed egestas tellus est aliqueget tristique <br />
                   nisley nullam pharetra tempor sed epsum</p>
                  <div>
                     <button className='learn-more'>learn More <GoArrowRight /></button>
                  </div>
            </div>
        </div>
        <div className='col-6' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <img src={future} className='h-100 w-100' />
        </div>
     </div> */}
     <div className='row great-achievement'>
       <div className='col-12'>
         <h5 className='great-title' data-aos="zoom-out-up" data-aos-offset="300" data-aos-easing="ease-in-sine">Great Achievement For Renewed</h5>          
       </div>
       <div className='col-12 achievement-renewed'>
        <div className='d-flex justify-content-center gap-5 achievement-boxs'>
        <div className='achievement' data-aos="zoom-out-up" data-aos-offset="400" data-aos-easing="ease-in-sine">
            <TbUserStar className='icons' />
            <h5>9000+</h5>
            <p>Saticfied Customer</p>
        </div>
        <div className='achievement' data-aos="zoom-out-up" data-aos-offset="400" data-aos-easing="ease-in-sine">
            <TbUsers className='icons' />
            <h5>2630+</h5>
            <p>Experience Members</p>
        </div>
        <div className='achievement' data-aos="zoom-out-up" data-aos-offset="400" data-aos-easing="ease-in-sine">
            <TbStars className='icons' />
            <h5>100%</h5>
            <p>Satisfaction Rate</p>
        </div>
        <div className='achievement' data-aos="zoom-out-up" data-aos-offset="400" data-aos-easing="ease-in-sine">
            <MdOutlineWorkHistory className='icons' />
            <h5>10+</h5>
            <p>Years experience</p>
        </div>
        <div className='achievement' data-aos="zoom-out-up" data-aos-offset="400" data-aos-easing="ease-in-sine">
            <LiaAwardSolid className='icons' />
            <h5>60+</h5>
            <p>Awards Winning</p>
        </div>
        </div>
       </div>
     </div>
     {/* <div className='row mb-5'>
        <div className='col-12 p-50 mt-5'>
            <h6 className='text-center' data-aos="fade-right" data-aos-offset="300"
            data-aos-easing="ease-in-sine">Our Team Member</h6>
            <h5 data-aos="fade-right" data-aos-offset="400"
            data-aos-easing="ease-in-sine">Meet Our Amazing Team</h5>
        </div>
        <div className='col-12'>
            <h5 className='director' data-aos="fade-right" data-aos-offset="400"
            data-aos-easing="ease-in-sine">Director & Chairmen</h5>
        </div>
        <div className='d-flex justify-content-center gap-5 flex-wrap' data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
            <div className='amazing-team'>
                  <h5>kaushik Paul</h5>
                  <p>Chairman & Managing Director</p>
                  <img src={kaushik} />
            </div>
            <div className='amazing-team'>
                  <h5>Rozmana Shaikh</h5>
                  <p>Director</p>
                  <img src={rozmana} />
            </div>
        </div>
        <div className='col-12 mt-5' data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
            <h5 className='director'>Business Management</h5>
        </div>
        <div className='d-flex justify-content-center gap-5' data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
            <div className='amazing-team'>
                  <h5>Mithun Dutta</h5>
                  <p>National HR Head</p>
                  <img src={mithun} />
            </div>
        </div>
        <div className='col-12 mt-5' data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
            <h5 className='director'>Training & Adminstration</h5>
        </div>
        <div className='d-flex justify-content-center gap-5 flex-wrap mb-5' data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
            <div className='amazing-team'>
                  <h5>Subodh Paul</h5>
                  <p>National Procurement Manager</p>
                  <img src={subodh} />
            </div>
            <div className='amazing-team'>
                  <h5>Manab Banerjee</h5>
                  <p>Operations Head</p>
                  <img src={manab} />
            </div>
            <div className='amazing-team'>
                  <h5>Indrajit Mishra</h5>
                  <p>Logistics manager</p>
                  <img src={indrajit} />
            </div>
        </div>
     </div> */}
   </main>
  )
}

export default About
