import React, { useEffect } from 'react'
import images31 from '../assets/images/img/31.png';
import images32 from '../assets/images/img/32.png';
import images33 from '../assets/images/img/33.png';
import images34 from '../assets/images/img/34.png';
import images35 from '../assets/images/img/35.png';
import images36 from '../assets/images/img/36.png';
import images37 from '../assets/images/img/37.png';
import images38 from '../assets/images/img/38.png';
import { AiOutlineRight } from "react-icons/ai";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Careers() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className="container-fluid main-contact">
          <div className='row'>
            <div className='col-12 p-0'>
              <div className='contact-title'>
                  <h5 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Careers</h5>
                  <h6 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Home <AiOutlineRight /> Careers</h6>
              </div>
            </div>
          </div>
          <div className="row">
           <div className="col-12 main-career">
            <div>
                <h5 className="main-title text-center" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine"><span>Current</span> Job Opening </h5>
            </div>
           </div>
        <div className="col-12 opening-current">
            <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening" data-aos="zoom-out-up" data-aos-offset="300"
     data-aos-easing="ease-in-sine"> 
                        <h5>UI-UX Designer</h5>
                        <h6>Openings : 01</h6>
                        <p>FRESHER</p>
                        <a href="https://forms.gle/rf45T4dRMBGBw2oJ8" target="_blank">Apply</a>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening" data-aos="zoom-out-up" data-aos-offset="300"
     data-aos-easing="ease-in-sine"> 
                        <h5>Web Designer</h5>
                        <h6>Openings : 01</h6>
                        <p>FRESHER</p>
                        <a href="https://forms.gle/rf45T4dRMBGBw2oJ8" target="_blank">Apply</a>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening" data-aos="zoom-out-up" data-aos-offset="300"
     data-aos-easing="ease-in-sine"> 
                        <h5>.Net developer</h5>
                        <h6>Openings : 2</h6>
                        <p>Fresher | Exprience</p>
                        <a href="https://forms.gle/rf45T4dRMBGBw2oJ8" target="_blank">Apply</a>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening" data-aos="zoom-out-up" data-aos-offset="200"
        data-aos-easing="ease-in-sine"> 
                           <h5>Digital marketing</h5>
                           <h6>Openings : 02</h6>
                           <p>1-3 years Exprience</p>
                           <a href="https://forms.gle/rf45T4dRMBGBw2oJ8" target="_blank">Apply</a>
                       </div>
                   </div>
                   <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6">
                       <div className="job-opening" data-aos="zoom-out-up" data-aos-offset="200"
        data-aos-easing="ease-in-sine"> 
                           <h5>Content writer</h5>
                           <h6>Openings : 01</h6>
                           <p>1-2 years Exprience</p>
                           <a href="https://forms.gle/rf45T4dRMBGBw2oJ8" target="_blank">Apply</a>
                       </div>
                   </div>
                   <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6">
                       <div className="job-opening" data-aos="zoom-out-up" data-aos-offset="200"
        data-aos-easing="ease-in-sine"> 
                        <h5>Flutter</h5>
                        <h6>Openings : 01</h6>
                        <p>1-2 years Exprience</p>
                        <a href="https://forms.gle/rf45T4dRMBGBw2oJ8" target="_blank">Apply</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12">
            <div>
                <h5 className="main-title text-center mt-0" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine"><span>We care</span> For Our Team </h5>
            </div>
        </div>
        <div className="col-12 opening-current">
            <div className="row">
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening gap-4" data-aos="zoom-in-down" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                        <img src={images31} alt="" className="img-fluid" />
                        <h6>Positive Work Environment</h6>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening gap-4" data-aos="zoom-in-down" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                        <img src={images32} alt="" className="img-fluid" />
                        <h6>Festival Celebrations</h6>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening gap-4" data-aos="zoom-in-down" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                        <img src={images33} alt="" className="img-fluid" />
                        <h6>Professional Development</h6>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening gap-4" data-aos="zoom-in-down" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                        <img src={images34} alt="" className="img-fluid" />
                        <h6>Paid Leaves</h6>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening gap-4" data-aos="zoom-in-down" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                        <img src={images35} alt="" className="img-fluid" />
                        <h6>Fun Activities</h6>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening gap-4" data-aos="zoom-in-down" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                        <img src={images36} alt="" className="img-fluid" />
                        <h6>Empower Learning</h6>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening gap-4" data-aos="zoom-in-down" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                        <img src={images37} alt="" className="img-fluid" />
                        <h6>Career Growth</h6>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div className="job-opening gap-4" data-aos="zoom-in-down" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                        <img src={images38} alt="" className="img-fluid" />
                        <h6>Performance Rewards</h6>
                    </div>
                </div>
            </div>
        </div>
          </div>
      </div>
  )
}

export default Careers
