import React , { useEffect, useState } from 'react'
import { AiOutlineRight } from "react-icons/ai";
import video1 from "../assets/images/video/office.mp4";
import video2 from "../assets/images/video/office-2.mp4";

function Gallery() {
    const [imagePaths, setImagePaths] = useState([]);

    useEffect(() => {
      const importImages = async () => {
        let paths = [];
        for (let i = 1; i <= 196; i++) {
          try {
            const image = await import(`../assets/images/happy_customer/${i}.webp`);
            paths.push(image.default);
          } catch (error) {
            console.error(`Error importing image ${i}:`, error);
          }
        }
        setImagePaths(paths);
      };
  
      importImages();
      window.scrollTo(0, 0)
    }, []);  

  return (
       <main className='container-fluid main-contact'>
       <div className='row'>
           <div className='col-12 p-0'>
               <div className='contact-title'>
                 <h5 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Gallery</h5>
                 <h6 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Home <AiOutlineRight /> Gallery</h6>
               </div>
           </div>
       </div>
       <div className="row">
     <div className="col-12 mt-4 d-flex justify-content-center align-items-center office-tour-customers">
          <h6>Office Tour</h6>
      </div>
     <div className="col-12">
         <div className="row d-flex justify-content-center align-items-center">
         <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
              <h6>Surat</h6>
            </div>
             <div className='col-12'>
             <div className='row d-flex justify-content-center align-items-center'>
             <div className="col-xxl-6 col-xl-6 col-lg-6">
                 <video width="100%" height="562px" controls>
                     <source src={video1} type="video/mp4"/>
                 </video>
             </div>
             </div>
             </div>
             <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
              <h6>Kolkata</h6>
            </div>
             <div className='col-12'>
              <div className='row d-flex justify-content-center align-items-center'>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                 <video width="100%" height="562px" controls>
                     <source src={video2} type="video/mp4" />
                 </video>
             </div>
              </div>
             </div>
         </div>
     </div>
 </div>
       <div className='row gallery-img pt-3'>
       <div className="col-12 d-flex justify-content-center align-items-center office-tour-customers">
           <h6>Our Customers</h6>
        </div>
         {imagePaths.map((imagePath, index) => (
           <div key={index} className='col-xxl-4 col-xl-6 col-lg-6' >
             <img src={imagePath} className='img-fluid' alt={`Gallery Image ${index}`} />
           </div>
         ))}
       </div>
     </main>
  )
}

export default Gallery
