import React from 'react'
import logo from '../assets/images/01.png'
import { FaInstagram } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { FiTwitter } from "react-icons/fi";
import { CiLinkedin } from "react-icons/ci";
import { AiOutlineYoutube } from "react-icons/ai";
import { NavLink } from 'react-router-dom';


function footer() {
  return (
      <footer className='main-footer'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xxl-4 content-logo'>
              <img src={logo} className='img-fluid mt-3' />
              <div className="social-icons desktop-icon">
              <div>
                  <a href="https://www.instagram.com/acceglobal/" className="text-decoration-none" target="”_blank”"><FaInstagram /></a>
                  <a href="https://www.facebook.com/acceglobal/" className="text-decoration-none" target="”_blank”"><CiFacebook /></a>
                  <a href="https://x.com/Acceglobal1" className="text-decoration-none" target="”_blank”"><FiTwitter /></a>
                  <a href="https://in.linkedin.com/company/acceglobalgroup" className="text-decoration-none" target="”_blank”"><CiLinkedin /></a>
                  <a href="https://www.youtube.com/channel/UCLTiTIOXhj1MRaWJVGsKrxg" className="text-decoration-none" target="”_blank”"><AiOutlineYoutube /></a>
              </div>
             </div>
            </div>
            <div className='col-xxl-8 d-flex gap-2 justify-content-around flex-wrap'>
              <div className='company-resources'>
              <div className='d-flex gap-5'>
              <div>
                <h6>Company</h6>
                <ul>
                <li><NavLink to='/about'>About us</NavLink></li>
                <li><NavLink to='/blog'>Blog</NavLink></li>
                <li><NavLink to='/contact'>Contact us</NavLink></li>
                <li><NavLink to='/careers'>Careers</NavLink></li>
                </ul>
              </div>
              <div>
                <h6>Our Product</h6>
                <ul>
                <li><a href="https://phonedekho.co.in/" target='_blank'>phonedekho</a></li>
                <li><a href="https://www.accetech.in/" target='_blank'>Acce tech </a></li>
                <li><NavLink to='/emilocker'>Emi Locker</NavLink></li>
                <li><a href="https://acceantitheftpro.in/" target='_blank'>Data Security App</a></li>
                </ul>
              </div>
              <div>
                <h6>why us</h6>
                <ul>
                <li><NavLink to='/gallery'>Gallery</NavLink></li>
                <li><NavLink to='/outlets'>Outlets</NavLink></li> 
                </ul>
              </div>
              </div>  
              </div>
              <div className='product-by'>
               <div className="contact">
                     <div className="mt-0">
                         <div>
                             <h6 className="m-0">Corporate Name</h6>
                             <p>
                                 ACCE GLOBAL SOFTWARE<br />
                                 PRIVATE LIMITED
                             </p>
                         </div>
                     </div>
                     <div className='mt-4'>
                         <div>
                             <h6 className="m-0">Corporate Address</h6>
                             <p>
                                 Office no. ASO 230, 2nd Floor, Astra<br /> Tower
                                 Action Area-2C, New Town, North<br /> 24
                                 Parganas, Kolkata- 700161, West Bengal
                             </p>
                         </div>
                     </div>
               </div>
              </div>
            </div>
          </div>
        </div>  
      </footer>
  )
}

export default footer
